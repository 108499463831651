// Example of a simple functional component in a file like Home.jsx

import React from 'react';

const Faq = () => {
  return (
      <div>
          <h1>FAQ</h1>
          <section>
              <p>
                  <strong>How do I book a session?</strong><br/>
                  Email me via the contact page.<br/><br/>
              </p>
          </section>
          <section>
              <p>
                  <strong>Where in the bay-area are the photoshoots conducted?</strong><br/>
                  I utilize various scenic locations on the Peninsula for photoshoots. Once you reach out, we'll work
                  together to select the ideal spot for your session.<br/><br/>
              </p>
          </section>
          <section>
              <p>
                  <strong>What are your rates?</strong><br/>
                  $350 for headshots and $400 per hour for other types of photography.<br/><br/>
              </p>
          </section>
          <section>
              <p>
                  <strong>How long is a professional headshot session?</strong><br/>
                  Roughly 20 minutes.<br/><br/>
              </p>
          </section>
          <section>
              <p>
                  <strong>How many photos do should I expect to receive from my headshot session?</strong><br/>
                  I deliver between ten to twenty meticulously chosen and edited photos per session.<br/><br/>
              </p>
          </section>
          <section>
              <p>
                  <strong>Do you edit the photos?</strong><br/>
                  Yes, I fully edit all photos I deliver. From color correction to subtle retouching,
                  I ensure you look your best, akin to your appearance on a particularly good day.<br/><br/>
              </p>
          </section>
          <section>
              <p>
                  <strong>How do I receive my photos?</strong><br/>
                  After completing the editing process, I will provide you with a Dropbox link where you can securely
                  download your photos.<br/><br/>
              </p>
          </section>
          <section>
              <p>
                  <strong>What is delivery time for the photos?</strong><br/>
                  You can expect to receive your photos no more than seven days after the session.<br/><br/>
              </p>
          </section>
      </div>
  );
};


export default Faq;
