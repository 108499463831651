import React from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/" className="nav-logo">BrianKCreative</Link>
      </div>
      <ul className="nav-links">
        <li>
          <Link to="/" className="nav-item">Home</Link>
        </li>
        <li>
          <Link to="/about" className="nav-item">About</Link>
        </li>
        <li>
          <Link to="/contact" className="nav-item">Contact</Link>
        </li>
        <li>
          <Link to="/faq" className="nav-item">FAQ</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
