import React from 'react';
import './about.css'; // Import the CSS for styling
import brianKlemmImage from "../assets/examples/brian_klemm.jpg"

const About = () => {
  return (
    <div className="about-container">
      <div className="about-image">
        {/* Replace 'your-photo.jpg' with the path to your photo */}
        <img src={brianKlemmImage} alt="Brian Klemm" />
      </div>
      <div className="about-text">
          <h1>Hello, I am Brian Klemm</h1>
          <p>
            In the lens of my camera lies the power to capture not just images, but stories, emotions, and moments.
            For over two decades, and over 200,000 photos later, I've honed the art of photography, yet it was
            five years ago that my passion transformed into a professional pursuit. Whether it's the professional
            poised for career elevation, a team's camaraderie, or the tranquil beauty of a local business, I am dedicated
            to connecting the viewer to the moment and or the people in my photos.
          </p>
          <p>
            My journey into photography was inspired by the profound connections that great visuals can forge, bridging
            distances and bringing people and places into the immediate reach of the observer. The click of my camera is
            an invitation to step into a scene, to get to know a stranger, or to revisit a cherished memory. Specializing
            in headshots, dynamic sports action, and marketing photography for both city's and companies, my approach melds
            technical expertise with an innate understanding of light and moment.
          </p>
          <p>
            Clients often remark on the calmness that defines their experience with me. This tranquility is not merely a
            demeanor but a reflection of confidence in capturing more than expected. To me, the camera is a natural
            extension of my being, allowing me to focus on interaction, not the equipment.
          </p>
          <p>
            My clients work directly with me—no intermediaries, no distractions, and no outsourcing. My goal? To ensure
            that when you see your photos, you feel more than satisfaction; you feel elation, nostalgia, pride, whatever
            "good" means for you in that captured slice of time.
          </p>
          <p>
            As the shutter closes, a headshot might launch a career, a player's goal might hold time still, and an event
            photo might distill joy from a fleeting day. This is the magic I seek to deliver through my lens, your story,
            beautifully told.
          </p>
      </div>
    </div>
  );
};

export default About;
