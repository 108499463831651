import React from 'react';
import Slider from 'react-slick';
import './home.css'; // Make sure to import your home-specific styles
import headshot1 from '../assets/examples/headshot_example_s_briankcreative.jpg'
import headshot2 from '../assets/examples/professional_headshot_example_2_briankcreative.jpg'
import headshot3 from '../assets/examples/professional_headshot_example_3_briankcreative.jpg'
import dance1 from '../assets/examples/dance_example.jpg'
import soccer from '../assets/examples/soccer_example_1.jpg'
import editing_example1 from '../assets/examples/Headshot Photo Editing Example BrianKCreative 1.jpg'
import editing_example2 from '../assets/examples/Headshot Photo Editing Example BrianKCreative 2.jpg'

const Home = () => {
      // Settings for the slider
      const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
      };
  return (
      <div className="home-container">
          <header className="hero-section">
              {/* Hero image or slider could go here */}
              <h1>Welcome to BrianKCreative</h1>
              <p>San Fransisco Bay Area Professional Headshots, Marketing, and Sports Photography.</p>
          </header>
          <section className="portfolio-section">
              <h2>Headshots</h2>
              <Slider {...settings}>
                  <div>
                      <img src={headshot1} alt="Headshot 1" className="slider-image"/>
                  </div>
                  <div>
                      <img src={headshot2} alt="Headshot 2" className="slider-image"/>
                  </div>
                  <div>
                      <img src={headshot3} alt="Headshot 3" className="slider-image"/>
                  </div>
              </Slider>
          </section>

          <section className="portfolio-section">
              <h2>Sports</h2>
              <Slider {...settings}>
                  <div>
                      <img src={dance1} alt="Dance" className="slider-image"/>
                  </div>
                  <div>
                      <img src={soccer} alt="Soccer" className="slider-image"/>
                  </div>
              </Slider>
          </section>

          <section className="editing-examples-section">
              <h2>Headshot Editing</h2>
              <div className="editing-gallery">
                  <div className="before-after-container">
                      <div className="before">
                          <h3>Before</h3>
                          <img src={editing_example1} alt="Before Headshot Editing"/>
                      </div>
                      <div className="after">
                          <h3>After</h3>
                          <img src={editing_example2} alt="After Headshot Editing"/>
                      </div>
                  </div>
                  {/* You can replicate the above div for more before/after examples */}
              </div>
          </section>

          <footer>
              {/* Footer content */}
          </footer>
      </div>
  );
};

export default Home;
