// Example of a simple functional component in a file like Home.jsx

import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>File Not Found 404</h1>
      {/* Other content */}
    </div>
  );
};

export default NotFound;
