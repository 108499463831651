// Footer.js
import React from 'react';
import './footer.css';

const Footer = () => {
  const year = new Date().getFullYear(); // This gets the current year

  return (
    <footer className="footer">
      <p>Professional Photography from San Francisco to Silicon
      Valley. &copy; {year} BrianKCreative - All rights reserved. </p>
    </footer>
  );
};

export default Footer;
