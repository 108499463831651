// Contact.js
import React, { useState } from 'react';
import './contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    service: 'headshots',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would handle form submission, e.g., sending data to an email service or API
    console.log(formData);
    // Provide feedback to the user or clear form
  };

  return (
    <div className="contact-container">
      <div className="contact-header">
        <h1>Contact Me</h1>
        <p>Got a project in mind or want to capture a special moment in the Bay Area Peninsula? I'd love to hear from
        you. Reach out using the form below, or via any of the methods listed,
        and let's create something beautiful together.</p>
      </div>

      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

        <label htmlFor="email">Email</label>
        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

        <label htmlFor="service">Service Interested In</label>
        <select id="service" name="service" value={formData.service} onChange={handleChange}>
          <option value="headshots">Professional Headshots</option>
          <option value="marketing">Marketing Photography</option>
          <option value="portraits">Sports Photography</option>
          <option value="other">Other</option>
        </select>

        <label htmlFor="message">Message</label>
        <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>

        <button type="submit">Send Message</button>
      </form>

      <div className="contact-info">
        <p>Email: Brian@BrianKCreative.com</p>
        <p>Phone: (678) 205-9634</p>
      </div>
    </div>
  );
};

export default Contact;
